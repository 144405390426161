import axios from "axios";
import {HTTP} from "../env"
export default {

  paypal_payment(param){
    const options = {
      method: 'POST',
      url: HTTP +'/paypal',
      headers: {'content-type': 'application/json'},
      params: {...param},
    };

    return new Promise ( (resolve,reject) => {
      axios(options).then( response => {
        resolve(response.data);
      }).catch( error => {
        reject(error);
      })
    })
  }
}
