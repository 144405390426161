import axios from "axios";
import {HTTP} from "../env"
export default {

  getPlans(){
    const options = {
      method: 'GET',
      url: HTTP +'/getplans',
      headers: {'content-type': 'application/json'},
    };

    return new Promise ( (resolve,reject) => {
      axios(options).then( response => {
        resolve(response.data.data);
      }).catch( error => {
        reject(error);
      })
    })
  }
}
