import axios from "axios";
import {HTTP} from "../env"

export default {
  /**
   * Get all questions baswd on type
   * @param {object} param
   * @returns {Promise<unknown>}
   */
  getQuestions(param) {
    const options = {
      method: 'GET',
      url: HTTP + '/getfaqs',
      headers: {'content-type': 'application/json'},
      params: {...param}
    };

    return new Promise((resolve, reject) => {
      axios(options).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      })
    })
  },

  getCategories() {
    const options = {
      method: 'GET',
      url: HTTP + '/getcategory',
      headers: {'content-type': 'application/json'},
    };

    return new Promise((resolve, reject) => {
      axios(options).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      })
    })
  }
}
