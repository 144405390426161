/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_COUNTRY(state, Country) {
    state.Country = Country;
  },
  SET_CITY(state, City) {
    state.City = City;
  },
  SET_ROLE(state, Role) {
    state.Role = Role;
  }
};
