import axios from "axios";

export default {

  saveGame(data){
    const options = {
      method: 'POST',
      url: 'https://wro-v.com/backend/api/admin/blockly/save',
      headers: {'content-type': 'application/json'},
      data: {...data}
    };

    return new Promise ( (resolve,reject) => {
      axios(options).then( response => {
        resolve(response);
      }).catch( error => {
        reject(error);
      })
    })
  },

  loadSavedGames(data) {
    const options = {
      method: 'GET',
      url: 'https://wro-v.com/backend/api/admin/blockly/load',
      headers: {'content-type': 'application/json'},
      params: {...data}
      // params: {organizer_id:64, id:23}
    };

    return new Promise ( (resolve,reject) => {
      axios(options).then( response => {
        resolve(response.data.data);
      }).catch( error => {
        reject(error);
      })
    })
  },

  showGame(params) {
    const options = {
      method: 'GET',
      url: 'https://wro-v.com/backend/api/admin/blockly/show',
      headers: {'content-type': 'application/json'},
      params: {...params}
    };

    return new Promise ( (resolve,reject) => {
      axios(options).then( response => {
        resolve(response.data.data);
      }).catch( error => {
        reject(error);
      })
    })
  }

}
