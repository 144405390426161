import axios from "axios";
import {HTTP} from "../../env"
export default {

  registerAsAmbassadors(data) {
    const options = {
      method: 'POST',
      url: HTTP +'/ambassadors',
      headers: {'content-type': 'application/json'},
      data: data,
    };

    return new Promise ( (resolve,reject) => {
      axios(options).then( response => {
        resolve(response.data.data);
      }).catch( error => {
        reject(error);
      })
    })
  },
}
