import getCountries from "./getCountries";
import competitions from "./competitions";
import plans from "./plans";
import faq from "./faq";
import blockly from "./blockly";
import home from './home/setting'
import payment from "./payment";
import organizer from "./organizer"

export default {
  getCountries,
  competitions,
  plans,
  faq,
  blockly,
  home,
  payment,
  organizer
}
