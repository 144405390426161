<template>
  <div class="flex w-full bg-img vx-row no-gutter justify-center items-center register-page">
    <div class="register-title">
      <div class="vx-col flex w-full bg-img vx-row items-center justify-center">
      <span class="text-xl text-primary text-center font-extrabold wro-hr-title">
        Register
      </span>
      </div>
    </div>

    <div class="vx-col w-full sm:m-0 m-4">
      <div slot="no-body" class="full-page-bg-color">
        <div class="vx-row no-gutter">
          <div class="vx-col w-full mx-auto self-center d-theme-dark-bg">
            <div class="register-tabs-container">
              <div class="vx-card__title"></div>
              <RegisterJWT></RegisterJWT>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterJWT from "../register/RegisterJWT"
export default {
  components: {
    RegisterJWT
  }
};
</script>

<style lang="scss">
.notes p {
  padding: 15px 0px;
  color: #AAAAAA;
}

.register-tabs-container {
  min-height: 577px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
