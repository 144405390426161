<template>
  <div class="w-full px-2">
    <div class="flex flex-wrap -mx-2">
      <div class="w-full xs:w-full bg sm:w-full px-2 mb-2">
        <div class="flex justify-between flex-wrap mb-5  bg-white w-full">
          <div class="">
            <p class="text-primary text-4xl font-bold">
              <span class="text-with-after">Ambassadors</span>
            </p>
          </div>
          <div class="">

            <vs-button
              class="rounded-full with_shadow md:w-auto float-right m-2"
              color="primary" @click="$router.push({name: 'AmbassadorsRegister'})"
              data-content="Register as ambassadors"
            >Register as ambassadors
            </vs-button>
          </div>
        </div>
        <div class="bg-white w-full mb-4">
          <div class="w-full py-2">
            <span class="text-2xl color-7c">
              Find Your Nearest Ambassador (Organizations Or Coaches)
            </span>
          </div>
          <div class="w-full py-2">
            <span class="text-left text-break text-sm color-7c">
                They are verified contact in your region that can help you with passion
              <br/>
                Please contact them if you have questions about the competitions they organize..
              <br/>
            </span>
          </div>
        </div>
        <div class="bg-white w-full mb-4">
          <div class="w-full py-2 text-center mb-4 world_image">
           <img src="@/assets/images/world_.png"/>
          </div>
        </div>
      </div>
    </div>

    <div class="py-6">
      <div class="flex justify-between  bg-white w-full  mb-4">
        <p class="w-full text-3xl font-bold text-primary">
          <span class="text-with-after">Global</span>
        </p>
      </div>

      <div class="grid gap-6 grid-cols-3 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6">
        <a href="https://global.wro-v.com/" target="_blank">
          <img class="responsive shadow-xl cursor-pointer ml-4 rounded-bl-3xl w-full w-20 rounded-t-3xl global-img"
               src="@/assets/images/global.png"
               alt="banner"/>
        </a>
      </div>
    </div>
    <div class="bg-white w-full">

      <div class="py-6" v-for="(el, idx) in continent">
        <div class="flex justify-between  bg-white w-full  mb-4">
          <p class="w-full text-3xl font-bold text-primary">
                 <span class="text-with-after">
                   {{ el }}
                 </span>
          </p>
          <div class="">
            <vs-button
              class="mb-4 rounded-full md:w-auto float-right m-2" type="border" icon-pack="feather"
              icon="icon-menu">
              {{ el }}
            </vs-button>
          </div>
        </div>

        <div class="grid gap-6 grid-cols-3 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6">
<!--          <div class="holder" v-for="(item,index) in data[idx]" :key="index">-->
<!--            <div class="wrapper-i">-->
<!--              <a v-if="item.link" :href="item.link" target="_blank">-->
<!--                <img class="responsive ml-4 rounded-bl-3xl w-full w-20 rounded-t-3xl" :src="item.flag" alt=""/>-->
<!--              </a>-->
<!--              <img v-else class="responsive ml-4 rounded-bl-3xl w-full w-20 rounded-t-3xl" :src="item.flag" alt=""/>-->
<!--            </div>-->
<!--          </div>-->

          <img class="responsive shadow-xl cursor-pointer ml-4 rounded-bl-3xl w-full w-20 rounded-t-3xl"
               src="@/assets/images/Plus.png"
               alt="banner"/>
        </div>
      </div>
    </div>

    <div class="vx-row flex justify-center  bg-white w-full mt-10">
      <div class="sm:w-1/3 md:w-1/3 lg:w-1/3  xl:w-1/3 w-full ">
        <h1 class="text-center align-bottom ml-4 mr-4 mt-6 ">
          Let's Work Together &
          <br/>
          Make a Better World
        </h1>
      </div>
      <div class="sm:w-1/3 md:w-1/3 lg:w-1/3  xl:w-1/3 w-full mt-6 d-flex flex-col">
        <vs-divider
          class="float-right mb-0  invisible md:visible lg:visible xl:visible w-3/5 mt-6 "
          border-style="solid"
          style=""
          position="center"
          color="primary"
        ></vs-divider>
      </div>
      <div
        class="flex flex-wrap sm:w-1/3 md:w-1/3 lg:w-1/3  xl:w-1/3 w-full "
      >
        <vs-button
          class="text-center ml-auto mr-auto rounded-full break-normal h-36 w-36"
          type="border"
          @click="$router.push('/contactUs')">Contact Us
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

export default {
  components: {

  },
  data() {
    return {
      accepted_countries: null,
      africa_countries: null,
      continent: {
        1: 'Africa',
        2: 'Asia',
        3: 'Europe',
        4: 'North America',
        5: 'South America',
        6: 'Australia',
        7: 'Antarctica'
      },
      data: {},
    };
  },
  mounted() {
    this.$API.getCountries.accepted_countries()
      .then(response => {
        this.accepted_countries = response.data.data;
        response.data.data.map(el => {
          if (!this.data.hasOwnProperty(el.continent.id)) {
            Vue.set(this.data, el.continent.id, [el])
          } else {
            this.data[el.continent.id].push(el)
          }
        });
      })
      .catch(error => {

        console.log(error);
      });
  }
};
</script>
<style lang="scss">
.vl {
  border-left: 6px solid #0093d2;
  height: 30px;
}

.wrapper-i {
  height: 100%;
}

.wrapper-i img {
  object-fit: cover;
}

.global-img {
  width: 100px !important;
  height: auto;
  padding: 16px;
}

.with_shadow {
  overflow: inherit !important;
  z-index: 2;
  padding-inline: 28px !important;
  &:after {
   content: attr(data-content);
   color: transparent;
   padding: 0 28px;
   height: 42px;
   background: transparent;
   border: 2px solid #0F6EFF;
   border-radius: 20px;
   position: absolute;
   right: -7px;
   top: 7px;
   z-index: 1;
   transition: 0.3s ease all;
 }
  &:hover {
    padding: 0 28px;
    &:after {
      padding: 0 28px;
      top: 0;
      right: 0;
      border-color: #0093D2;
    }
  }
}

.world_image {
  img {
    object-fit: contain;
    height: auto;
    width: 100%;
    max-height: 500px;
    margin: auto;
  }
}
</style>
