import axios from "@/axios";
import {HTTP} from "@/env";

export default {

  getSetting(){

    const options = {
      method: 'GET',
      url: HTTP +'/getSettings',
      headers: {'content-type': 'application/json'},
    };

    return new Promise( (resolve,reject) =>  {
      axios(options).then( response => {
        resolve( response.data );
      }).catch( error => {
        reject ( error.response.data );
      });
    });

  },

  homePhotos() {

    const options = {
      method: 'GET',
      url: HTTP +'/getphotos',
      headers: {'content-type': 'application/json'},
    };

    return new Promise( (resolve,reject) =>  {
      axios(options).then( response => {
        resolve( response.data );
      }).catch( error => {
        reject ( error.response.data );
      });
    });

  }
}
