import axios from "axios";
import {HTTP} from "../env"
export default {
    accepted_countries(){
      const options = {
        method: 'GET',
        url: HTTP +'/continent_countries',
        headers: {'content-type': 'application/json'},
      };

      return new Promise ( (resolve,reject) => {
        axios(options).then( response => {
          resolve(response);
        }).catch( error => {
          reject(error);
        })
      })
    }
}
