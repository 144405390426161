// en, de, fr, pt
export default {
  en: {
    CommercialRegistrationID: "Commercial registration ID",
    Address: "Address",
    ZipCode: "Zip code",
    DriverBalance: "Driver balance",
    AccountStatus: "Account status",
    VacationMode: "Vacation mode",
    VehicleType: "Vehicle type",
    VehicleOption: "Vehicle option",
    UserBalance: "User balance",
    showNoDataAvailable: "No data available... Please try again",
    Error: "Error",
    showError: "Something went wrong... Please try again",
    Update: "Update",
    showUpdate: "Send successfully...",
    ResetPassword: "Reset password",
    showCheckEmail: "Kindly check you email for new password...",
    showPasswordChanged: "Password changed...",
    Dashboard: "Dashboard",
    WaitingForVerficationDrivers: "Waiting for verfication",
    Height: "Height",
    Length: "Length",
    Width: "Width",
    Country: "Country",
    CustomerId: "CUSTOMER ID",
    StartCountry: "Start country",
    StartCountryCode: "Start country code",
    EndCountry: "End country",
    EndCountryCode: "End country code",
    FindNearByDrivers: "Find near by drivers",
    eCommerce: "eCommerce",
    Chat: "Chat",
    Email: "Email",
    Calendar: "Calendar",
    FullCalendar: "Full calendar",
    SimpleCalendar: "Simple calendar",
    Shop: "Shop",
    Cart: "Cart",
    Checkout: "Checkout",
    UI: "UI",
    Colors: "Colors",
    Card: "Card",
    Basic: "Basic",
    Statistics: "Statistics",
    Analytics: "Analytics",
    Table: "Table",
    Alert: "Alert",
    Avatar: "Avatar",
    Chip: "Chip",
    Collapse: "Collapse",
    Dialogs: "Dialogs",
    Divider: "Divider",
    DropDown: "DropDown",
    List: "List",
    Loading: "Loading",
    Navbar: "Navbar",
    Notifications: "Notifications",
    Pagination: "Pagination",
    Popup: "Popup",
    Progress: "Progress",
    Sidebar: "Sidebar",
    Slider: "Slider",
    Tabs: "Tabs",
    Tooltip: "Tooltip",
    Upload: "Upload",
    FormsAndTable: "Forms & Table",
    FormElements: "Form Elements",
    Select: "Select",
    Switch: "Switch",
    Checkbox: "Checkbox",
    Radio: "Radio",
    Input: "Input",
    NumberInput: "Number input",
    Login: "Login",
    LoginTitle: "Welcome back, Please login to your account...",
    BackToLogin: "Back to login",
    Register: "Register",
    ForgotPassword: "Forgot password ?",
    RememberMe: "Remember me",
    CreateAccount: "Create account",
    CreateAccountTitle: "Fill the below form to create a new account...",
    ForgotPasswordPage: "Forgot Password",
    ForgotPasswordPageTitle:
      "Welcome back, Please Fill the below form to reset password.",
    LockScreen: "Lock screen",
    Miscellaneous: "Miscellaneous",
    ComingSoon: "Coming soon",
    404: "404",
    500: "500",
    NotAuthorized: "Not Authorized",
    Maintenance: "Maintenance",
    Filters: "Filters",
    Profile: "Profile",
    Invoice: "Invoice",
    FAQ: "FAQ",
    Search: "Search",
    UpdateImage: "Update image",
    UpdateLogo: "Update logo",
    AssignToMe: "Assign to me",
    KnowledgeBase: "Knowledge base",
    ChartsAndMaps: "Charts & Maps",
    GoogleMap: "Google  map",
    Extensions: "Extensions",
    QuillEditor: "Quill editor",
    Datepicker: "Datepicker",
    DatetimePicker: "Datetime Picker",
    AccessControl: "Access control",
    I18n: "I18n",
    Carousel: "Carousel",
    Clipboard: "Clipboard",
    ContextMenu: "Context menu",
    StarRatings: "Star ratings",
    Autocomplete: "Autocomplete",
    Tree: "Tree",
    Schedual: "Schedual",
    Import: "Import",
    Export: "Export",
    ExportSelected: "Export selected",
    Others: "Others",
    MenuLevels: "Menu levels",
    DisabledMenu: "Disabled menu",
    Support: "Support",
    Documentation: "Documentation",
    RaiseSupport: "Raise support",
    demoTitle: "Card title",
    Language: "Language",
    Facility: "Facility",
    ClosingDate: "Closing date",
    PaymentStatus: "Payment status",
    DriverPaymentStatus: "Driver payment status",
    QuestionType: "Question type",
    QuestionLevel: "Question level",
    QuestionBank: "Question bank",
    Rating: "Rating",
    Cancel: "Cancel",
    Actions: "Actions",
    Nationality: "Nationality",
    Status: "Status",
    ContactType: "Contact type",
    Customer: "Customer",
    DriverID: "Driver ID",
    UserManagement: "User management",
    Users: "Users",
    UserID: "User ID",
    RegistrationDate: "Registration date",
    CountryCode: "Country code",
    PaymentMethod: "Payment method",
    Date: "Date",
    NumberOfTrips: "Number of trips",
    CargoType: "Cargo type ",
    CargoDescription: "Cargo description",
    DriverRemovedSuccessfuly: "Driver removed successfuly",
    CargoValue: "Cargo value",
    CargoWeight: "Cargo weight",
    SaveChanges: "Save changes",
    TripDistance: "Trip distance",
    TripDuration: "Trip duration",
    StartLocation: "Start location ",
    StartPlaceId: "Start place ID",
    StartAddress: "Start address",
    EndAddress: "End address",
    EndLocation: "End location",
    EndPlaceId: "End PlaceId",
    ViewDriverDetails: "View driver details",
    DriverDetails: "Driver details",
    ViewUserDetails: "View user details",
    ViewSubmittedOrders: "Submitted  orders",
    Offer: "Offer",
    Duration: "Duration",
    Distance: "Distance",

    Find: "Find",
    ThisDriverNotFound: "This driver not found",
    EditBankInformation: "Edit Bank information",
    SendText: "Send Text",
    Send: "Send",
    UserNotFound: "User not found",
    Submit: "Submit",
    AddDriverToCompany: "Add driver to company",
    ALL: "All",
    TransactionsHistory: "Transactions history",
    Balance: "Balance",
    WalletCountryCode: "Wallet country code",
    TransactionType: "Transaction type",
    RecieptNumber: "Reciept number",
    TransactionNumber: "Transaction number",
    AccessDenied: " you have no access rights to login",
    RemoveDriver: "Remove driver",
    Remove: "Remove",
    YouAreAboutToRemoveThis: "You are about to remove",
    ViewOnMap: "View on  map",
    CreditLimit: "Credit limit",
    ConfirmPassword: "Confirm Password",
    CompanyWalletBalance: "Company Wallet balance",
    ApplyBankInfoToAllDrivers: "Apply bank information to all drivers",
    YouAreAboutToUpdateBankInfoForAllDrivers:
      "You are about to update bank information for all drivers",
    UpdateStatus: "Update status",
    QMark: "?",
    Saturday: "Saturday",
    Jan: "Jan",
    Feb: "Feb",
    Mar: "Mar",
    Apr: "Apr",
    May: "May",
    Jun: "Jun",
    Jul: "Jul",
    Aug: "Aug",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec",
    PasswordError: "Invalid Password... Please try again..!",
    EmailError: "Invalid Email... Please try again..!",
    CountryError: "Invalid country code... Please try again..!",
    GeneralError: "Something went wrong... Please try again..!",
    EmailExistError: "Email exist",
    PhoneNumberNotValid: "Phone number is not valid",
    SuccessfulRegistration: "Successful registration..",
    Success: "Success",
    EmailsentSuccessfully: "Email sent successfully..",
    Apply: "Apply",
    Value: "Value",
    send: "send",
    IncorrectCode: "Incorrect code",
    Save: "Save",
    PleaseFillAllRequiredData: "Please fill all required data...",
    ConfirmRemove: "Confirm remove",
    ChangeType: "Change type",
    DriversPerStatus: "Drivers Per status",
    YouAreAlreadyloggedin: "You are already logged in!",
    Deactivate: "Deactivate",
    EditCompanyBankAccountInformation: "Edit company bank account information",
    CompanyTransactionHistory: "Company transaction history",
    CompanyWallet: "Company wallet"
  },

  ar: {
    Required: " * ",
    FollowOnMap: "تتبع علي الخريطة",
    Amount: "القيمة",
    Next: "التالي",
    BackToHome: "العودة الي الصفحة الرئيسية",
    OperationDoneSuccessfuly: "العملية تمت بنجاح",
    YouAreAboutToDeActivateThis: "أنت على وشك تعطيل ",
    YouAreAboutToActivateThis: "أنت على وشك تفعيل ",
    Trips: "رحلات",
    DriverDetails: " معلومات السائق",
    CargoValue: "قيمة الشحن",
    CargoDescription: "وصف البضائع",
    CargoType: "نوع البضائع",
    NumberOfTrips: "عدد الرحلات",
    PaymentMethod: "طريقة الدفع أو السداد",
    Patients: "المرضى",
    Patient: "مريض",
    UserDetails: "بيانات المستخدم",
    PatientDetails: "بيانات المريض",
    Clinic: "العيادة",
    PatientsList: "قائمة المرضى",
    UserName: "اسم المستخدم",
    VacationMode: "وضع العطلة",
    VehicleType: "نوع السيارة",
    VehicleOption: "خيارات السيارة",
    UserBalance: "رصيد المستخدم",
    Filters: "فلترة",
    Actions: "أجراءات",
    showNoDataAvailable: "لا توجد بيانات متاحة ... يرجى المحاولة مرة أخرى",
    Error: "خطأ",
    showError: "حدث خطأ ما. أعد المحاولة من فضلك",
    Update: "تحديث",
    showUpdate: "تم التحديث بنجاح ...",
    Password: "كلمة المرور",
    ConfirmPassword: " تاكيد كلمة المرور",
    ResetPassword: "إعادة تعيين كلمة المرور",
    showCheckEmail: "يرجى التحقق من بريدك الإلكتروني لكلمة المرور الجديدة ...",
    showPasswordChanged: "تم تغيير كلمة السر...",
    StartCity: "مدينة الانطلاق",
    EndCity: "مدينة الوصول",
    StartState: "محافظة الانطلاق",
    EndState: "محافظة الانطلاق",
    Insurance: "التأمين",
    InsuranceValue: "قيمة التأمين",
    ClientInformation: "معلومات العميل",
    BankAccountInfo: "معلومات الحساب البنكي",
    VehicleInfo: "معلومات المركبة",
    DriverInfo: "معلومات السائق",
    Height: "الارتفاع",
    Length: "الطول",
    Width: "العرض",
    StartCountry: "بلد الانطلاق",
    StartCountryCode: "كود بلد الانطلاق",
    EndCountry: "بلد الوصول",
    EndCountryCode: "كود بلد الوصول",
    FindNearByDrivers: "إيجاد السائقين القريبين",
    eCommerce: "eCommerce",

    Sunday: "الاحد",
    Monday: "الاثنين",
    Tuesday: "الثلاثاء",
    Wednesday: "الاربع",
    Thursday: "الخميس",
    Friday: "الجمعة",
    Saturday: "السبت",
    Jan: "يناير",
    Feb: "فبراير",
    Mar: "مارس",
    Apr: "ابريل",
    May: "مايو",
    Jun: "يونية",
    Jul: "يوليو",
    Aug: "اغسطس",
    Sep: "سبتمبر",
    Oct: "اكتوبر",
    Nov: "نوفمبر",
    Dec: "ديسمبر"
  }
};
